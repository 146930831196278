<template>
    <div class="section-offset">
        <div class="information-container" :key="language">
            <academy-information-inner :categoryId="parseInt(id)" />
            <academy-a-side :categoryId="parseInt(id)" />
        </div>
    </div>
</template>

<script>
import AcademyInformationInner from "@/components/Academy/AcademyInformationInner.vue"
import AcademyASide from "@/components/Academy/AcademyASide.vue"
import i18n from "@/plugins/i18n"

export default {
    name: "TheAcademy",
    components: { AcademyInformationInner, AcademyASide },
    computed: {
        language() {
            return i18n.locale
        },
    },
    props: {
        id: {
            type: String,
            default: "1",
        },
    },
}
</script>
