<template>
    <aside class="information-tabs-wrapper">
        <academy-information-tabs-content :categoryId="categoryId" />
    </aside>
</template>

<script>
import AcademyInformationTabsContent from "@/components/Academy/AcademyInformationTabsContent.vue";

export default {
    name: "AcademyASide",
    components: { AcademyInformationTabsContent },
    props: {
        categoryId: {
            type: Number,
            default: 0,
        },
    },
}
</script>
