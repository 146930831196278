<template>
    <div class="news-card-more">
        <button class="news-card-more__button btn-reset" type="button">{{ text }}</button>
    </div>
</template>

<script>
export default {
    name: "AcademyButtonRead",
    props: {
        text: {
            type: String,
            default: "Read More...",
        },
    },
}
</script>
