<template>
    <div class="information-inner">
        <academy-tabs :categoryId="categoryId">
            <template v-slot="props">
                <academy-tabs-content :categoryId="props.content.id" :categoryName="props.content.name" />
            </template>
        </academy-tabs>
    </div>
</template>

<script>
import AcademyTabs from "@/components/Academy/AcademyTabs.vue"
import AcademyTabsContent from "@/components/Academy/AcademyTabsContent.vue"

export default {
    name: "AcademyInformationInner",
    components: { AcademyTabs, AcademyTabsContent },
    props: {
        categoryId: {
            type: Number,
            default: 1,
        },
    },
}
</script>
