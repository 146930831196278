<template>
  <transition-group tag="div" class="tabs-content tabs-offset" v-if="posts.length > 0">
    <article class="news-card" v-for="post in posts" :key="post.id">
      <header class="news-card-header news-card__header">
        <h2 class="news-card__title" v-if="$i18n.locale === 'ru'">{{ post.name_ru }}</h2>
        <h2 class="news-card__title" v-else>{{ post.name }}</h2>
        <div class="news-card__category">
                    <span v-if="categoryName">
                        {{ categoryName }}
                    </span>
          <span>{{ formattedDate(post.created) }}</span>
        </div>
      </header>
      <div class="news-card__image">
        <img v-if="$i18n.locale === 'ru'" :src="post.img_ru" alt="preview"/>
        <img v-else :src="post.img" alt="preview"/>
      </div>
      <div class="news-card-info">
        <p class="news-card-info__desc" v-if="$i18n.locale === 'ru'">{{ post.excerpt_ru }}</p>
        <p class="news-card-info__desc" v-else>{{ post.excerpt }}</p>
      </div>
      <router-link class="card-link" :to="{ name: 'showPost', params: { postId: post.id } }">
        <academy-button-read :text="$t('academy.readMore')"/>
      </router-link>
    </article>
  </transition-group>
</template>

<script>
import {mapGetters} from "vuex"
import AcademyButtonRead from "@/components/Academy/AcademyButtonRead.vue"

export default {
  name: "AcademyTabsContent",
  components: {AcademyButtonRead},
  props: {
    categoryId: {
      required: true,
      type: Number,
    },
    categoryName: {
      type: String,
    },
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    categoryId() {
      this.fetchData();
    },
  },
  computed: {
    ...mapGetters({
      posts: "allSpecificPosts",
    }),
  },
  methods: {
    fetchData() {
      console.log(this.categoryId);
      if (this.categoryId === 1) {
        this.$store.dispatch("fetchAcademyPosts")
      } else {
        this.$store.dispatch("fetchNewsPosts", { limit: 3 })
      }
    },
    /**
     * Get Date By Timestamp
     *
     * @returns {string}
     * @param date
     */
    formattedDate(date) {
      return this.$moment(date * 1000).format("DD MMMM YYYY")
    },
  },
}
</script>
