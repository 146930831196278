<template>
    <div>
        <div class="content-tabs__wrapper" v-if="categories.length > 0">
            <div class="content-tabs">
                <router-link
                    :to="{ name: 'showCategory', params: { id: category.id.toString() } }"
                    class="content-tab"
                    :class="{ 'content-tab--active': isActive(category.id) }"
                    v-for="category in categories.slice(0, 2)"
                    :key="category.id"
                >
                    <div v-if="$i18n.locale === 'ru'">
                        {{ category.name_ru }}
                    </div>
                    <div v-else>
                        {{ category.name }}
                    </div>
                </router-link>
            </div>
        </div>
        <slot v-if="$i18n.locale === 'ru'" v-bind:content="{ id: categoryId, name: category.name_ru }" />
        <slot v-else v-bind:content="{ id: categoryId, name: category.name }" />
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    name: "AcademyTabs",
    props: {
        categoryId: {
            type: Number,
            default: 1,
        },
    },
    created() {
        this.$store.dispatch("fetchCategories")
        this.$store.dispatch("fetchCategory", this.categoryId)
    },
    computed: {
        ...mapGetters({
            categories: "allCategories",
            category: "getCategory",
        }),
    },
    methods: {
        /**
         * Is Tab Active
         *
         * @param categoryId
         * @returns {boolean}
         */
        isActive(categoryId) {
            return this.categoryId === categoryId
        },
    },
}
</script>
